import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import styles from '@/components/Cards/WhatsHot/Testimonial/TestimonialCard.style';

const useStyles = makeStyles()(styles);

export default function TestimonialCard({ data }) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { author, content, submittedAt } = data;
  const label = t('Testimonials');

  return (
    <Card className={classes.card}>
      <CardContent className={classes.cardContent}>
        <div className={classes.contentInner}>
          <div className={classes.widget}>
            <div className={classes.offerContent}>
              {label && (
                <div className={classes.chipWrapper}>
                  <Chip size="small" label={label} className={classes.chip} />
                </div>
              )}
              <Typography className={classes.content}>{content}</Typography>
            </div>
            <div className={classes.saveTimeMain}>
              <div className={classes.commentWrapper}>
                <div className={classes.commentItem}>
                  <div className={classes.commentContent}>
                    <div className={classes.commentImg}>
                      <Image
                        src="/images/svg/comment-icon.svg"
                        alt="Comment Review"
                        width={32}
                        height={32}
                        quality={100}
                      />
                    </div>
                    <Typography className={classes.author}>{author}</Typography>
                  </div>
                </div>
              </div>
              <div className={classes.dateWrapper}>
                <Typography className={classes.date}>{submittedAt}</Typography>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
