const styles = (theme) => ({
  card: {
    padding: 13,
    width: '100%',
    backgroundColor: theme.palette.common.white,
    boxShadow: '0px 2px 8px rgb(0 0 0 / 5%)',
    borderRadius: 8,
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  },
  header: {
    marginBottom: 8,
  },
  subTitle: {
    display: 'flex',
    alignItems: 'flex-start',
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize16,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightBold,
  },
  dirBtn: {
    padding: 0,
    minWidth: 'auto',
    color: theme.palette.secondary.main,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'capitalize',
    whiteSpace: 'nowrap',
  },
  infoWrapper: {
    '& p.MuiTypography-root': {
      marginBottom: 8,
      '&:last-child': {
        marginBottom: 0,
      },
    },
  },
  distanceText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightMedium,
  },
  timingSummary: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.common.grey300,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
    textTransform: 'capitalize',
    textAlign: 'left',
    '& > span:last-child': {
      minWidth: 110,
      textAlign: 'right',
      paddingLeft: 8,
    },
  },
  directionSec: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.common.grey300,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
    textTransform: 'capitalize',
    textAlign: 'left',
    paddingTop: 8,
    borderTop: `1px solid ${theme.palette.common.grey20}`,
    '& > span:last-child': {
      minWidth: 110,
      textAlign: 'right',
      paddingLeft: 8,
    },
  },
  timingSummaryTime: {
    textTransform: 'lowercase',
  },
  phoneBtn: {
    padding: 0,
    textDecoration: 'underline',
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
});

export default styles;
