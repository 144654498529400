import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import _flatMap from 'lodash/flatMap';
import _get from 'lodash/get';

import { Typography } from '@mui/material';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

import { makeStyles } from 'tss-react/mui';

import getArabicDayWithLabel from '@lumirental/lumi-web-shared/lib/utils/date/getArabicDayWithLabel';
import getTimeWithArabicLabel from '@lumirental/lumi-web-shared/lib/utils/date/getTimeWithArabicLabel';
import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';
import goToMapURL from '@lumirental/lumi-web-shared/lib/utils/goToMapURL';

import NavigateForwardIcon from '@/components/Icons/NavigateForward';

import styles from '@/components/Lists/Location/Locations.style';

const useStyles = makeStyles()(styles);

const NoLocationFound = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.noLocationFoundWrapper}>
      <Container>
        <Grid container>
          <Grid item sm={12}>
            <Typography
              variant="h5"
              className={classes.notLocationFoundContent}
            >
              {t('no_locations_found')}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

const TimingSummary = ({ timingSummary, language }) => {
  const { classes } = useStyles();

  const isArabic = language === 'ar';

  return timingSummary.map((timing) => {
    const dayLabel = _get(timing, 'dayLabel', '');
    const timeLabel = _get(timing, 'timeLabel', '');

    const formattedTimeLabel = isArabic
      ? getTimeWithArabicLabel(timeLabel)
      : timeLabel;
    const formattedDayLabel = isArabic
      ? getArabicDayWithLabel(dayLabel)
      : dayLabel;

    const timeSlots = timing?.timeRanges?.length
      ? timing.timeRanges.map((timeRange) => {
          const formattedTime = isArabic
            ? getTimeWithArabicLabel(timeRange)
            : timeRange;
          return formattedTime;
        })
      : [formattedTimeLabel];

    return (
      <div key={`${dayLabel}-${timeLabel}`} className={classes.timingWrapper}>
        <Typography className={classes.dayLabel}>
          {formattedDayLabel}
        </Typography>
        <Typography className={classes.timeLabel}>
          <span className={classes.timingGroup}>
            {timeSlots.map((slot) => {
              return <span key={`${slot}-${formattedDayLabel}`}>{slot}</span>;
            })}
          </span>
        </Typography>
      </div>
    );
  });
};

const Locations = ({ isBranchSuccess, branchRegionList, language }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const branchListState = _flatMap(branchRegionList).slice(0, 2);
  // go to branch location
  const goToBranchLocation = (branch) => {
    const directionsURL = _get(branch, 'directions', '');

    if (directionsURL.length !== 0) {
      // go to map Location.
      goToMapURL(directionsURL);
    }
  };

  return isBranchSuccess ? (
    branchListState &&
      branchListState.map((location) => {
        const id = _get(location, 'id');
        const regionName = _get(location, 'regionName', '');
        const branchName = _get(location, 'name', '');
        const cityName = _get(location, 'cityName', '');
        const directions = _get(location, 'directions', '');
        const phoneNumber = _get(location, 'phoneNumber', '');
        const timingSummary = _get(location, 'timingSummary', []);

        const hasDirections = directions.length !== 0;

        return (
          <div
            className={classes.tableRow}
            key={`${id}_${regionName}_${cityName}`}
          >
            <Container>
              <Grid container>
                <Grid item sm={12}>
                  <List className={classes.tableCellGroup}>
                    <ListItem className={classes.tableCell}>
                      <Typography className={classes.tableContent}>
                        {regionName}
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.tableCell}>
                      <Typography className={classes.tableContent}>
                        {branchName}
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.tableCell}>
                      <Typography className={classes.tableContent}>
                        {cityName}
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.tableCell}>
                      <TimingSummary
                        timingSummary={timingSummary}
                        language={language}
                      />
                    </ListItem>
                    <ListItem className={classes.tableCell}>
                      <Typography className={classes.tableContent}>
                        {phoneNumber}
                      </Typography>
                    </ListItem>
                    <ListItem className={classes.tableCell}>
                      <Button
                        disabled={!hasDirections}
                        variant="text"
                        onClick={() => goToBranchLocation(location)}
                        className={classes.dirBtn}
                      >
                        {t('Get_direction')}
                      </Button>
                    </ListItem>
                  </List>
                </Grid>
              </Grid>
            </Container>
          </div>
        );
      })
  ) : (
    <NoLocationFound />
  );
};
export default function LocationList({
  branchRegionList,
  getRegionNameByRegionId,
  getBranchesByRegionId,
  isBranchSuccess,
  language,
  showViewMore = false,
}) {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.locationTableBody}>
      <Locations
        isBranchSuccess={isBranchSuccess}
        branchRegionList={branchRegionList}
        language={language}
      />
      {showViewMore && isBranchSuccess && (
        <div className={classes.btnWrapper}>
          <Link
            passHref
            href={getLocaleRoute(language, 'locations')}
            legacyBehavior
          >
            <Button
              variant="text"
              className={classes.viewAllBtn}
              endIcon={<NavigateForwardIcon language={language} />}
            >
              {t('View_all_locations')}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}
