import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import styles from '@/components/Cards/FleetCarousel/FleetCarouselCard.style';

const useStyles = makeStyles()(styles);

export default function FleetCarouselCard({ imgSrc, fleetClass, price }) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Card className={classes.card}>
      {imgSrc && (
        <div className={classes.cardImg}>
          <Image
            src={imgSrc}
            alt={fleetClass}
            quality={100}
            width={300}
            height={190}
            layout="responsive"
            objectFit="contain"
          />
        </div>
      )}
      <CardContent className={classes.cardContent}>
        {fleetClass && (
          <Typography className={classes.cardTitle}>{fleetClass}</Typography>
        )}
        {price && (
          <div className={classes.cardPriceWrapper}>
            <Typography className={classes.cardText}>
              {t('starts_from')}
            </Typography>
            <Typography className={classes.cardText}>
              <span>{price}</span>
            </Typography>
          </div>
        )}
      </CardContent>
    </Card>
  );
}
