const styles = (theme) => ({
  card: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    direction: 'ltr',
    position: 'relative',
    boxShadow: 'none',
  },
  bgWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  cardContent: {
    padding: 16,
    minHeight: 220,
    '&:last-child': {
      paddingBottom: 16,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      bottom: 0,
      background:
        'linear-gradient(99.71deg, rgba(0, 48, 87, 0.6) 0%, rgba(0, 48, 87, 0) 92.33%)',
      opacity: 0.8,
    },
  },

  contentInner: {
    padding: 8,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: theme.zIndex.zIndex100,
    minHeight: 188,
  },
  offerWidget: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    paddingRight: 16,
  },
  promoField: {
    maxWidth: 210,
    marginBottom: 8,
    '& .MuiOutlinedInput-adornedStart': {
      paddingLeft: 0,
    },
    '& .MuiInputAdornment-positionStart': {
      height: 'auto',
      width: 52,
      marginLeft: 12,
      marginRight: 0,
    },
    '& .MuiOutlinedInput-root.Mui-focused': {
      '& fieldset': {
        borderColor: theme.palette.common.white,
      },
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: theme.palette.common.white,
      },
      '&:hover': {
        '& fieldset': {
          borderColor: theme.palette.common.white,
        },
      },
    },
    '& fieldset': {
      padding: '2px 8px',
      color: theme.palette.common.white,
      border: `1px dashed ${theme.palette.common.white}`,
    },
    '& input': {
      padding: 8,
      paddingLeft: 0,
      marginLeft: 18,
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize18,
      lineHeight: 1.5,
      fontWeight: theme.typography.fontWeightSemiBold,
    },
  },
  getOfferText: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize20,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 8,
  },
  content: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
    marginBottom: 8,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    display: '-webkit-box !important',
    lineClamp: 3,
    boxOrient: 'vertical',
  },
  readMore: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 8,
  },
  btnWrapper: {
    padding: 0,
  },
  copyBtn: {
    padding: '12px 24px',
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize16,
    lineHeight: 1.4,
    fontWeight: theme.typography.fontWeightBold,
    borderRadius: 100,
    textTransform: 'none',
    '&:hover, &:focus, &:active': {
      backgroundColor: `${theme.palette.secondary.main} !important`,
    },
  },
  chip: {
    height: 19,
    padding: '0 10px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.blue400,
    '& > .MuiChip-label': {
      padding: 0,
      color: theme.palette.common.blue400,
      fontSize: theme.typography.fontSize14,
      lineHeight: 1.5,
    },
  },
  cardText: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
});

export default styles;
