const styles = (theme) => ({
  wrapper: {
    padding: '24px 0px',
    '@media(max-width: 600px)': {
      padding: '16px 20px',
    },
  },
  titleWrapper: {
    marginBottom: 24,
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize28,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 4,
    '@media(max-width: 600px)': {
      fontSize: theme.typography.fontSize20,
    },
  },
  subTitle: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    marginBottom: 8,
    '& > b': {
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  mapWrapper: {
    position: 'relative',
    textAlign: 'center',
    width: '100%',
    minHeight: '42vw',
    marginBottom: 16,
  },
  mapImg: {
    maxWidth: '100%',
    width: '100%',
  },
});

export default styles;
