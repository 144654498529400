const styles = (theme) => ({
  tableHeaderWrapper: {
    padding: 20,
    backgroundColor: theme.palette.common.blue400,
  },
  tableHeader: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  tableCell: {
    padding: 0,
    width: '14%',
    display: 'inline-block',
    '&:nth-of-type(4)': {
      width: '30%',
    },
    '&:not(:last-child)': {
      paddingRight: 12,
    },
  },
  tableHeading: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 8,
  },
});

export default styles;
