const styles = (theme) => ({
  wrapper: {
    padding: '42px 24px',
    backgroundColor: theme.palette.common.lightBodyBackground,
  },
  containerWrapper: {
    '@media(max-width: 600px)': {
      padding: 0,
    },
  },
  stepItem: {
    padding: '0 12px',
    maxWidth: '100%',
  },

  btnWrapper: {
    maxWidth: 340,
    margin: '0 auto',
  },
  searchBtn: {
    paddingTop: 20,
    paddingBottom: 20,
  },
  sliderWrapper: {
    display: 'block',
    '& .slick-slider': {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      userSelect: 'none',
      touchAction: 'pan-y',
      tapHighlightColor: 'transparent',
    },
    '& .slick-list': {
      position: 'relative',
      display: 'block',
      overflow: 'hidden',
      margin: 0,
      padding: 0,
      '&:focus': {
        outline: 'none',
      },
      '&.dragging': {
        cursor: 'hand',
      },
    },
    '& .slick-slider .slick-track, & .slick-slider .slick-list': {
      transform: 'translate3d(0, 0, 0)',
    },
    '& .slick-track': {
      position: 'relative',
      top: 0,
      left: 0,
      display: 'block',
      marginLeft: 'auto',
      marginRight: 'auto',
      '&:before, &:after': {
        display: 'table',
        content: '""',
      },
      '&:after': {
        clear: 'both',
      },
    },
    '& .slick-loading .slick-track': {
      visibility: 'hidden',
    },
    '& .slick-slide': {
      display: 'none',
      float: 'left',
      height: '100%',
      minHeight: 1,
    },
    "& [dir='rtl'] .slick-slide ": {
      float: 'right',
    },
    '& .slick-slide img': {
      display: 'block',
    },
    '& .slick-slide.slick-loading img': {
      display: 'none',
    },
    '& .slick-slide.dragging img': {
      pointerEvents: 'none',
    },
    '& .slick-initialized .slick-slide': {
      display: 'block',
    },
    '& .slick-loading .slick-slide': {
      visibility: 'hidden',
    },
    '& .slick-vertical .slick-slide': {
      display: 'block',
      height: 'auto',
      border: '1px solid transparent',
    },
    '& .slick-arrow.slick-hidden': {
      display: 'none',
    },

    // arrow and dots section styling

    '& .slick-loading .slick-list': {
      background: `${theme.palette.common.white} url("./ajax-loader.gif") center center no-repeat`,
    },
    '& .slick-prev, & .slick-next': {
      fontSize: 0,
      lineHeight: 0,
      position: 'absolute',
      top: '50%',
      display: 'block',
      width: 24,
      height: 24,
      padding: '4px 0',
      transform: 'translate(0, -50%)',
      cursor: 'pointer',
      color: 'transparent',
      border: 'none',
      outline: 'none',
      background: theme.palette.common.blue400,
      zIndex: 9,
      '& img': {
        filter:
          'invert(98%) sepia(55%) saturate(48%) hue-rotate(269deg) brightness(121%) contrast(100%)',
      },
      borderRadius: '100%',
      '& .MuiIconButton-label': {
        color: theme.palette.common.white,
      },
      '&:hover, &:focus': {
        color: 'transparent',
        outline: 'none',
        background: theme.palette.common.blue400,
        '&:before': {
          opacity: 1,
        },
      },
    },
    '& .slick-prev.slick-disabled:before, & .slick-next.slick-disabled:before':
      {
        opacity: 0.25,
      },

    '& .slick-prev': {
      left: -85,
      '@media(max-width: 1360px)': {
        left: -20,
      },
    },
    '& [dir="rtl"] .slick-prev': {
      right: -85,
      left: 'auto',
      '@media(max-width: 1360px)': {
        right: -20,
      },
    },

    '& .slick-next': {
      right: -85,
      '@media(max-width: 1360px)': {
        right: -20,
      },
    },
    '& [dir="rtl"] .slick-next': {
      right: 'auto',
      left: -85,
      '@media(max-width: 1360px)': {
        left: -20,
      },
    },

    // '& [dir="rtl"] .slick-next:before': {
    //   content: '"←"'
    // },
    '& .slick-dotted.slick-slider': {
      marginBottom: 30,
    },
    '& .slick-dots': {
      position: 'absolute',
      bottom: -25,
      display: 'block',
      width: '100%',
      padding: 0,
      margin: 0,
      listStyle: 'none',
      textAlign: 'center',
    },
    '& .slick-dots li': {
      position: 'relative',
      display: 'inline-block',
      width: 20,
      height: 20,
      margin: '0 5px',
      padding: 0,
      cursor: 'pointer',
    },
    '& .slick-dots li button': {
      fontSize: 0,
      lineHeight: 0,
      display: 'block',
      width: 20,
      height: 20,
      padding: 5,
      cursor: 'pointer',
      color: 'transparent',
      border: 0,
      outline: 'none',
      background: 'transparent',
      '&:hover, &:focus': {
        outline: 'none',
      },
    },
    '& .slick-dots li button:hover:before, & .slick-dots li button:focus:before':
      {
        opacity: 1,
      },
    '& .slick-dots li button:before': {
      fontSize: 6,
      lineHeight: 20,
      position: 'absolute',
      top: 0,
      left: 0,
      width: 20,
      height: 20,
      content: '"•"',
      textAlign: 'center',
      opacity: '0.25',
      color: 'black',
      fontSmoothing: 'grayscale',
    },
    '& .slick-dots li.slick-active button:before': {
      opacity: 0.75,
      color: 'black',
    },
  },
});

export default styles;
