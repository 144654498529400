'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import styles from './DownloadAppBannerBlock.style';

const useStyles = makeStyles()(styles);

export default function DownloadAppBannerBlock({ language }) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isArabic = language === 'ar';

  const baseAppImgSrc = '/images/png/';
  const androidAppImg = isArabic
    ? 'google-play-badge-ar.png'
    : 'google-play-badge-en.png';

  const iosAppImg = isArabic
    ? 'apple-store-badge-ar.png'
    : 'apple-store-badge-en.png';

  const androidAppImgSrc = `${baseAppImgSrc}${androidAppImg}`;
  const iosAppImgSrc = `${baseAppImgSrc}${iosAppImg}`;

  const androidAndIOSAppImgSrc = isArabic
    ? '/images/png/lg-android-and-ios-app-ar.png'
    : '/images/png/lg-android-and-ios-app-en.png';

  return (
    <div className={classes.mainWrapper} id="view_app">
      <div className={classes.innerWrapper}>
        <Container>
          <Grid container className={classes.container}>
            <Grid item sm={12} md={6}>
              <div className={classes.downloadAppImageWrapper}>
                <Image
                  className={classes.downloadAppImage}
                  alt="Save time with Lumi app"
                  src={androidAndIOSAppImgSrc}
                  width={490}
                  height={320}
                  objectFit="cover"
                  quality={100}
                />
              </div>
            </Grid>
            <Grid item sm={12} md={6}>
              <div className={classes.saveTimeSection}>
                <div className={classes.saveTimeHeader}>
                  <Typography variant="h4" className={classes.saveTimeTitle}>
                    {t('Save_your_time_at_the_pickup_location')}
                  </Typography>
                  <Typography variant="h6" className={classes.saveTimeSubtitle}>
                    {t('Upload_your_documents_from_the_application')}
                  </Typography>
                </div>
                <div className={classes.saveTimeMain}>
                  <div className={classes.saveTimeBarcodeWrapper}>
                    <Image
                      src="/images/svg/lumi-app-barcode.svg"
                      alt="Lumi Barcode"
                      width={138}
                      height={138}
                      quality={100}
                    />
                  </div>
                  <div className={classes.saveTimeAppWrapper}>
                    <div className={classes.saveTimeAppItem}>
                      <div className={classes.appItemLeft}>
                        <a
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          href="https://play.google.com/store/apps/details?id=com.seera.lumiapp "
                          className={classes.appLink}
                        >
                          <Image
                            src={androidAppImgSrc}
                            alt="Lumi Android App"
                            width={140}
                            height={42}
                            quality={100}
                          />
                        </a>
                      </div>
                    </div>

                    <div className={classes.saveTimeAppItem}>
                      <div className={classes.appItemLeft}>
                        <a
                          target="_blank"
                          rel="nofollow noopener noreferrer"
                          href="https://apps.apple.com/ae/app/lumi-car-rental/id1489446283"
                          className={classes.appLink}
                        >
                          <Image
                            src={iosAppImgSrc}
                            alt="Lumi IOS App"
                            width={140}
                            height={42}
                            quality={100}
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </div>
  );
}
