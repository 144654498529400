'use client';

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import _get from 'lodash/get';
import _keys from 'lodash/keys';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';

import LocationCard from '@/components/Cards/Location';
import { LOCATION_LIST_THRESHOLD } from '@/constants';

import styles from '@/components/Lists/Location/LocationList.style';

const useStyles = makeStyles()(styles);

const NoLocationFound = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  return (
    <div className={classes.noLocationFoundWrapper}>
      <Grid container>
        <Grid item sm={12}>
          <Typography variant="h5" className={classes.notLocationFoundContent}>
            {t('no_locations_found')}
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
};

// TODO: will refactor this once we cleanup mobx
export default function LocationList({
  language,
  branchRegionList,
  isBranchSuccess,
  showViewMore,
  getRegionNameByRegionId,
  getBranchesByRegionId,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const isSeoPage = !(getRegionNameByRegionId && getRegionNameByRegionId);

  // local state
  const [branchRegionListState, setBranchRegionListState] = useState();

  const listThreshold = LOCATION_LIST_THRESHOLD / 2;

  // set Local state when branchRegionlist not empty.
  useEffect(() => {
    if (!showViewMore && branchRegionList) {
      setBranchRegionListState(
        isSeoPage ? _keys(branchRegionList) : branchRegionList,
      );
      return;
    }
    if (branchRegionList) {
      setBranchRegionListState(
        (isSeoPage ? _keys(branchRegionList) : branchRegionList).slice(0, 1),
      );
    }
  }, [branchRegionList, showViewMore]);

  const getBranchListByRegionId = (regionItem) => {
    let branchList = isSeoPage
      ? branchRegionList[regionItem]
      : getBranchesByRegionId(Number(regionItem));
    // truncate list if showViewMore is true;
    if (showViewMore && branchList.length > listThreshold) {
      branchList = branchList.slice(0, listThreshold);
    }
    return branchList;
  };

  const LocationList = isBranchSuccess ? (
    branchRegionListState &&
    branchRegionListState.map((regionItem) => {
      const regionName = isSeoPage
        ? branchRegionList[regionItem][0].regionName
        : getRegionNameByRegionId(Number(regionItem));
      const branchList = getBranchListByRegionId(regionItem);

      return (
        <div className={classes.section} key={regionName}>
          <Typography className={classes.title}>{regionName}</Typography>
          {branchList &&
            branchList.map((location) => {
              const id = _get(location, 'id', 0);
              return (
                <LocationCard
                  key={id}
                  language={language}
                  location={location}
                />
              );
            })}
        </div>
      );
    })
  ) : (
    <NoLocationFound />
  );

  return (
    <div className={classes.locationTableBody}>
      {LocationList}
      {showViewMore && isBranchSuccess && (
        <div className={classes.btnWrapper}>
          <Link
            passHref
            href={getLocaleRoute(language, 'locations')}
            legacyBehavior
          >
            <Button variant="text" className={classes.viewAllBtn}>
              {t('View_all_locations')}
            </Button>
          </Link>
        </div>
      )}
    </div>
  );
}

export {};
