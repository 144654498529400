const styles = (theme) => ({
  card: {
    width: '100%',
    height: '100%',
    borderRadius: 8,
    direction: 'ltr',
    position: 'relative',
    boxShadow: 'none',
  },
  bgWrap: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  cardContent: {
    padding: 16,
    minHeight: 220,
    '&:last-child': {
      paddingBottom: 16,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      bottom: 0,
      background:
        'linear-gradient(99.71deg, rgba(0, 48, 87, 0.6) 0%, rgba(0, 48, 87, 0) 92.33%)',
      opacity: 0.8,
    },
  },

  contentInner: {
    padding: 8,
    position: 'relative',
    display: 'flex',
    justifyContent: 'space-between',
    zIndex: theme.zIndex.zIndex100,
  },
  widget: {
    paddingRight: 16,
  },
  content: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 8,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    display: '-webkit-box !important',
    lineClamp: 2,
    boxOrient: 'vertical',
  },

  chip: {
    height: 19,
    padding: '0 10px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.blue400,
    '& > .MuiChip-label': {
      padding: 0,
      color: theme.palette.common.blue400,
      fontSize: theme.typography.fontSize14,
      lineHeight: 1.5,
    },
  },
  barCodeImgWrapper: {
    lineHeight: 0,
    marginBottom: 12,
  },
  barCodeImg: {
    display: 'inline-block !important',
    width: '100%',
    maxWidth: 64,
  },
  appImgWrapper: {
    display: 'flex',
  },
  appImgItem: {
    marginRight: 6,
  },
  appLink: {
    '& > img': {
      maxWidth: 110,
      maxHeight: 34,
      width: '100%',
    },
  },
});

export default styles;
