const styles = (theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.palette.common.white,
    padding: 16,
    boxShadow: 'none',
    border: 'none',
    borderRadius: 8,
  },
  chip: {
    height: 26,
    backgroundColor: theme.palette.common.blue400,
    marginBottom: 16,
    '& > .MuiChip-label': {
      padding: '0 10px',
      color: theme.palette.common.white,
      fontSize: theme.typography.fontSize12,
      lineHeight: 1.5,
    },
  },
  cardImg: {
    minHeight: 190,
    marginBottom: 18,
    width: '100%',
  },
  cardContent: {
    textAlign: 'center',
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  cardText: {
    display: 'inline-block',
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize14,
    verticalAlign: 'middle',
    '& > span': {
      margin: '0 6px',
      color: theme.palette.common.blue400,
      fontSize: theme.typography.fontSize20,
      fontWeight: theme.typography.fontWeightBold,
    },
  },
  cardPriceWrapper: {
    direction: 'ltr',
    marginBottom: 24,
  },
  btnWrapper: {
    textAlign: 'center',
    paddingTop: 0,
  },
  viewAllBtn: {
    fontSize: theme.typography.fontSize16,
    fontWeight: theme.typography.fontWeightBold,
    minWidth: 136,
    textTransform: 'capitalize',
    padding: '12px 16px',
  },
});

export default styles;
