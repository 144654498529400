import IconButton from '@mui/material/IconButton';

import NavigateBackIcon from '@/components/Icons/NavigateBack';

export default function SlickArrowLeft({
  currentSlide,
  slideCount,
  language,
  ...props
}) {
  return (
    <IconButton {...props} component="span">
      <NavigateBackIcon language={language} />
    </IconButton>
  );
}
