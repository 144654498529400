import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import styles from '@/components/Cards/OurVehicles/OurVehiclesCard.style';

const useStyles = makeStyles()(styles);

export default function OurVehiclesCard({
  imgSrc,
  fleetClass,
  price,
  handlViewAllFleetClick,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <Card className={classes.card}>
      {fleetClass && <Chip label={fleetClass} className={classes.chip} />}
      {imgSrc && (
        <div className={classes.cardImg}>
          <Image
            src={imgSrc}
            alt={fleetClass}
            quality={100}
            width={300}
            height={190}
            layout="responsive"
            objectFit="contain"
          />
        </div>
      )}
      <CardContent className={classes.cardContent}>
        {price && (
          <div className={classes.cardPriceWrapper}>
            <Typography className={classes.cardText}>
              {t('starts_from')}
            </Typography>
            <Typography className={classes.cardText}>
              <span>{price}</span>
            </Typography>
            <Typography className={classes.cardText}>{t('per_day')}</Typography>
          </div>
        )}
        <div className={classes.btnWrapper}>
          <Button
            variant="contained"
            className={classes.viewAllBtn}
            data-testid="fleetViewAllButton"
            onClick={() => handlViewAllFleetClick(fleetClass)}
          >
            {t('view_all_web')}
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
