'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';

import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import WhyLumiCard from '@/components/Cards/WhyLumi';

import DesktopOnly from '../../components/Responsive/DesktopOnly';

import styles from '@/blocks/OurService/OurServiceBlock.style';

const useStyles = makeStyles()(styles);

export default function OurServiceBlock({ data }) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.wrapper} id="why_lumi">
      <Container>
        <Grid item sm={12}>
          <div className={classes.titleWrapper}>
            <Typography variant="h4" className={classes.title}>
              {t('Why_lumi')}
            </Typography>
            <DesktopOnly>
              <Typography className={classes.subTitle}>
                {t('Lumi_gives_seamless_booking')}
              </Typography>
            </DesktopOnly>
          </div>
        </Grid>
        <Grid container className={classes.ourServiceContentRow}>
          {data &&
            data.map((item) => {
              const id = _get(item, 'id', 0);
              const title = _get(item, 'title', '');
              const content = _get(item, 'content', '');
              const thumbnail = _get(item, 'thumbnail.url', '');
              return (
                <Grid
                  key={id}
                  item
                  xs={12}
                  sm={12}
                  md={6}
                  className={classes.serviceItem}
                >
                  <WhyLumiCard
                    iconSrc={thumbnail}
                    title={title}
                    text={content}
                  />
                </Grid>
              );
            })}
        </Grid>
      </Container>
    </div>
  );
}
