'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { usePathname } from 'next/navigation';
import _get from 'lodash/get';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';

import { makeStyles } from 'tss-react/mui';

import SCREENS_NAME from '@lumirental/lumi-web-shared/lib/constants/app/SCREENS_NAME';
import { useLanguageContext } from '@lumirental/lumi-web-shared/lib/contexts/Language.context';

import { manageSmoothScrolling } from '@/utils';
import { searchClicked } from '@/utils/gtm';

import FleetCarouselCard from '@/components/Cards/FleetCarousel';
import NavigateBackIcon from '@/components/Icons/NavigateBack';
import NavigateForwardIcon from '@/components/Icons/NavigateForward';

import styles from '@/blocks/FleetCarousel/FleetCarouselBlock.style';

const useStyles = makeStyles()(styles);

export default function FleetCarouselBlock({ tariffList }) {
  const pathName = usePathname();
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { isArabic } = useLanguageContext();

  const SlickArrowLeft = ({ currentSlide, slideCount, ...props }) => (
    <IconButton {...props} component="span" className={classes.arrowIcon}>
      <NavigateBackIcon />
    </IconButton>
  );

  const SlickArrowRight = ({ currentSlide, slideCount, ...props }) => (
    <IconButton {...props} component="span" className={classes.arrowIcon}>
      <NavigateForwardIcon />
    </IconButton>
  );

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rtl: isArabic,
    nextArrow: <SlickArrowLeft />,
    prevArrow: <SlickArrowRight />,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleClick = () => {
    const screenName = SCREENS_NAME[pathName];

    // scroll to search widget
    manageSmoothScrolling('#searchBlock');

    // send GA event
    searchClicked(screenName);
  };

  return (
    <div className={classes.wrapper}>
      <Container className={classes.containerWrapper}>
        <Grid container className={classes.sliderWrapper}>
          <Slider {...settings}>
            {tariffList.map((tariffItem, index) => {
              const fleetClass = _get(tariffItem, 'class', '');
              const dailyPrice = _get(tariffItem, 'dailyPrice', '');
              const thumbnail = _get(tariffItem, 'thumbnail', '');
              const id = _get(tariffItem, `${index}_${fleetClass}`, 0);
              return (
                <Grid key={id} item className={classes.stepItem}>
                  <FleetCarouselCard
                    imgSrc={thumbnail}
                    fleetClass={fleetClass}
                    price={dailyPrice}
                  />
                </Grid>
              );
            })}
          </Slider>

          <Grid item sm={12}>
            <div className={classes.btnWrapper}>
              <Button
                fullWidth
                variant="contained"
                className={classes.searchBtn}
                data-testid="fleetSearchButton"
                onClick={handleClick}
              >
                {t('search')}
              </Button>
            </div>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
