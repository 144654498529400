'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';

import { Container } from '@mui/material';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import { manageSmoothScrolling } from '@/utils';
import { onTapEvent } from '@/utils/gtm';

import OurStepCard from '@/components/Cards/OurStep';
import DesktopOnly from '@/components/Responsive/DesktopOnly';

import styles from '@/blocks/OurStep/OurStepBlock.style';

const useStyles = makeStyles()(styles);

export default function OurStepBlock({ data }) {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();

  const handleClick = () => {
    // scroll to search widget
    manageSmoothScrolling('#searchBlock');

    // send GA event
    onTapEvent('home_fleet_carousel', 'fleet_crousel_cta_tapped');
  };

  return (
    <div className={classes.wrapper} id="our_step">
      <Container>
        <Grid item sm={12}>
          <div className={classes.titleWrapper}>
            <Typography variant="h3" className={classes.title}>
              {t('Our_four_steps')}
            </Typography>
            <DesktopOnly>
              <Typography variant="h3" className={classes.colorTitle}>
                {t('seamless_booking_experience')}
              </Typography>
            </DesktopOnly>
          </div>
        </Grid>
        <Grid container className={classes.stepItemWrapper}>
          {data &&
            data.map((item, index) => {
              const id = _get(item, 'id', 0);
              const title = _get(item, 'title', '');
              const content = _get(item, 'content', '');
              const thumbnail = _get(item, 'thumbnail.url', '');
              const count = index + 1;
              return (
                <Grid
                  key={id}
                  item
                  xs={12}
                  sm={12}
                  md={3}
                  className={classes.stepItem}
                >
                  <OurStepCard
                    title={title}
                    content={content}
                    imgSrc={thumbnail}
                    count={count}
                    language={i18n.language}
                  />
                </Grid>
              );
            })}
        </Grid>
        {/* <div className={classes.btnWrapper}>
          <Button
            variant="contained"
            data-testid="fleetSearchButton"
            onClick={handleClick}
            sx={{
              display: { xs: 'block', sm: 'block', md: 'inline-block' },
              width: { xs: '100%', sm: '100%', md: 'auto' },
            }}
          >
            {t('find_a_car_web')}
          </Button>
        </div> */}
      </Container>
    </div>
  );
}
