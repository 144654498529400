const styles = (theme) => ({
  locationTableBody: {
    backgroundColor: theme.palette.common.lightBodyBackground,
  },
  tableRow: {
    padding: 20,
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.common.white,
    },
  },
  tableCellGroup: {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
  },
  tableCell: {
    padding: 0,
    width: '14%',
    display: 'inline-block',
    '&:nth-of-type(4)': {
      width: '30%',
    },
  },
  tableContent: {
    color: theme.palette.common.grey400,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.6,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 8,
  },
  timingWrapper: {
    '&:not(:last-child)': {
      marginBottom: 16,
    },
  },
  dayLabel: {
    color: theme.palette.common.grey400,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.6,
    fontWeight: theme.typography.fontWeightSemiBold,
    textTransform: 'capitalize',
  },
  timeLabel: {
    color: theme.palette.common.grey400,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.6,
    fontWeight: theme.typography.fontWeightSemiBold,
    textTransform: 'lowercase',
  },
  btnWrapper: {
    padding: '42px 0',
    textAlign: 'center',
    background: theme.palette.common.white,
  },
  dirBtn: {
    marginTop: -15,
    textTransform: 'none',
    color: theme.palette.common.purple400,
  },
  viewAllBtn: {
    textTransform: 'none',
    '& .MuiButton-iconSizeMedium': {
      backgroundColor: theme.palette.common.blue400,
      color: theme.palette.common.white,
      padding: 2,
      borderRadius: '100%',
    },
  },
  noLocationFoundWrapper: {
    padding: '40px 0',
    textAlign: 'center',
  },
  notLocationFoundContent: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize22,
    lineHeight: 1.6,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  timingGroup: {
    display: 'flex',
    flexDirection: 'column',
  },
});

export default styles;
