import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import { onTapEvent } from '@/utils/gtm';

import styles from '@/components/Cards/WhatsHot/Offer/OfferCard.style';

const useStyles = makeStyles()(styles);

export default function OfferCard({ data, showSnackbar }) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { backgroundImg, code, content } = data;
  const label = t('Offers');

  const promoCodeRef = useRef(null);

  const handleCopyCode = (e) => {
    // send GA event
    onTapEvent('home_whatshot', 'copy_code_tapped');

    promoCodeRef.current.select();
    document.execCommand('copy');
    e.target.focus();

    showSnackbar();
  };

  return (
    <Card className={classes.card}>
      {backgroundImg && (
        <div className={classes.bgWrap}>
          <Image
            alt={content}
            src={backgroundImg}
            layout="fill"
            objectFit="cover"
            quality={100}
          />
        </div>
      )}
      <CardContent className={classes.cardContent}>
        <div className={classes.contentInner}>
          <div className={classes.offerWidget}>
            <div className={classes.offerContent}>
              <TextField
                fullWidth
                variant="outlined"
                value={code}
                className={classes.promoField}
                InputProps={{
                  inputProps: {
                    ref: promoCodeRef,
                  },
                  readOnly: true,
                  startAdornment: (
                    <InputAdornment position="start" aria-haspopup="true">
                      <Image
                        src="/images/svg/fleet/lumi-offer-img.png"
                        alt="Lumi Offer"
                        width={22}
                        height={22}
                        quality={100}
                      />
                    </InputAdornment>
                  ),
                }}
              />

              <Typography className={classes.content}>{content}</Typography>
            </div>
            <div className={classes.btnWrapper}>
              <Button
                variant="contained"
                className={classes.copyBtn}
                onClick={handleCopyCode}
              >
                {t('copy_code')}
              </Button>
            </div>
          </div>
          {label && (
            <Chip size="small" label={label} className={classes.chip} />
          )}
        </div>
      </CardContent>
    </Card>
  );
}
