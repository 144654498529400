'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-slick';
import { useRouter } from 'next/navigation';
import _get from 'lodash/get';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';
import navigateAndScrollToTop from '@lumirental/lumi-web-shared/lib/utils/lang/navigateAndScrollToTop';

import OurVehiclesCard from '@/components/Cards/OurVehicles';
import LeftArrow from '@/components/SlickArrows/LeftArrow';
import RightArrow from '@/components/SlickArrows/RightArrow';

import styles from '@/blocks/OurVehicles/OurVehiclesBlock.style';

const useStyles = makeStyles()(styles);

export default function OurVehiclesBlock({ tariffList, language }) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const router = useRouter();

  // derive
  const isArabic = language === LANGUAGES.AR;
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    rtl: isArabic,
    nextArrow: <RightArrow language={language} />,
    prevArrow: <LeftArrow language={language} />,
    responsive: [
      {
        breakpoint: 1260,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handlViewAllFleetClick = (fleetClass) => {
    const route = `vehicles?class=${fleetClass}`;
    navigateAndScrollToTop(router, route);
  };

  return (
    <div className={classes.wrapper}>
      <Container>
        <Grid item sm={12} className={classes.sliderContent}>
          <div className={classes.titleWrapper}>
            <Typography variant="h2" className={classes.title}>
              {t('our_vehicles')}
            </Typography>
            <Typography className={classes.subtitle}>
              {t('variety_a_vehicles')}
            </Typography>
          </div>
          <Grid container className={classes.sliderWrapper}>
            <Slider {...settings}>
              {tariffList &&
                tariffList.map((tariffItem, index) => {
                  const fleetClass = _get(tariffItem, 'class', '');
                  const dailyPrice = _get(tariffItem, 'dailyPrice', '');
                  const thumbnail = _get(tariffItem, 'thumbnail', '');
                  const id = _get(tariffItem, `${index}_${fleetClass}`, 0);
                  const vehicleKey = `${id}_${thumbnail}`;
                  return (
                    <Grid key={vehicleKey} item className={classes.stepItem}>
                      <OurVehiclesCard
                        imgSrc={thumbnail}
                        fleetClass={fleetClass}
                        price={dailyPrice}
                        handlViewAllFleetClick={handlViewAllFleetClick}
                      />
                    </Grid>
                  );
                })}
            </Slider>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
