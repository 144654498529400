'use client';

import React from 'react';

import { Container } from '@mui/material';
import Grid from '@mui/material/Grid';

import { makeStyles } from 'tss-react/mui';

import LocationList from '@/components/Lists/Location';

import styles from '@/blocks/LocationTable/LocationsBlock.style';

const useStyles = makeStyles()(styles);

export default function LocationsBlock({
  branchRegionList,
  getRegionNameByRegionId,
  getBranchesByRegionId,
  isBranchSuccess,
  language,
  showViewMore = false,
}) {
  const { classes } = useStyles();

  return (
    // <div className={classes.locationTableWrapper} id="our_location">
    <Container className={classes.locationTableWrapper} id="our_location">
      <Grid item xs={12}>
        <LocationList
          language={language}
          branchRegionList={branchRegionList}
          isBranchSuccess={isBranchSuccess}
          showViewMore={showViewMore}
          getRegionNameByRegionId={getRegionNameByRegionId}
          getBranchesByRegionId={getBranchesByRegionId}
        />
      </Grid>
    </Container>
    // </div>
  );
}
