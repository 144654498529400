'use client';

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Autoplay from 'embla-carousel-autoplay';
import useEmblaCarousel from 'embla-carousel-react';
import _get from 'lodash/get';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import { cardComponentFactory, cardDataFactory } from '@/utils/cardFactory';

import DesktopOnly from '@/components/Responsive/DesktopOnly';
import LeftArrow from '@/components/SlickArrows/LeftArrow';
import RightArrow from '@/components/SlickArrows/RightArrow';

import styles from '@/blocks/Carousel/CarouselBlock.style';

const useStyles = makeStyles()(styles);

export default function CarouselBlock({ data, language }) {
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true }, [Autoplay()]);

  const scrollPrev = useCallback(() => {
    if (emblaApi) emblaApi.scrollPrev();
  }, [emblaApi]);

  const scrollNext = useCallback(() => {
    if (emblaApi) emblaApi.scrollNext();
  }, [emblaApi]);

  // state for snackbar message when code copied
  const [openSnackBar, setOpenSnackBar] = useState(false);

  const { classes } = useStyles();
  const { t } = useTranslation();

  const showSnackbar = () => {
    setOpenSnackBar(true);
  };

  const handleSnackBarClose = (evt, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenSnackBar(false);
  };

  /**
   * Returns a React component for the offers card
   * @param {Object} smallItem
   * @param {number} index
   * @returns {Component}
   */
  const getCardComponent = (smallItem, index) => {
    const cardType = _get(smallItem, 'cardType', '');
    const formattedData = cardDataFactory(cardType, smallItem);
    const cardComponent = _get(cardComponentFactory, `${cardType}.comp`, null);
    const key = `${index}_${cardType}`;

    if (cardComponent) {
      return (
        <ListItem key={key} className={`${classes.slideItem} embla__slide`}>
          {cardComponent(formattedData, language, showSnackbar)}
        </ListItem>
      );
    }
    return null;
  };

  return (
    <>
      <div className={classes.wrapper} id="whats_hot">
        <Container className={classes.carouselContainer}>
          <Grid item xs={12} className={classes.wrapperGrid}>
            <div className={classes.titleWrapper}>
              <Typography variant="h4" className={classes.title}>
                {t('What’s_hot')}
              </Typography>
              <DesktopOnly>
                <Typography className={classes.subTitle}>
                  {t('Dont_wait_for')}
                </Typography>
              </DesktopOnly>
            </div>
            <List className={`${classes.sliderWrapper} embla`}>
              <div ref={emblaRef} className={classes.embla__viewport}>
                <div className={classes.embla__container}>
                  {data?.map((item) => {
                    if (item) {
                      // if an array with data comes from strapi
                      // eg. news card, offers card
                      if (item.length > 0) {
                        return item.map((smallItem, index) =>
                          getCardComponent(smallItem, index),
                        );
                      }
                      // if an object type comes from strapi
                      // eg. application card
                      if (item.length === undefined) {
                        return getCardComponent(item, 0);
                      }
                      // if an empty array comes from strapi
                      // eg. no offers
                      if (item.length === 0) {
                        return null;
                      }
                    }
                    // if a null comes from strapi
                    // e. no application card
                    return null;
                  })}
                </div>
              </div>
              <button className={classes.embla__prev} onClick={scrollPrev}>
                <LeftArrow aria-label="Previous" language={language} />
              </button>
              <button className={classes.embla__next} onClick={scrollNext}>
                <RightArrow aria-label="Next" language={language} />
              </button>
            </List>
          </Grid>
        </Container>
      </div>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={openSnackBar}
        onClose={handleSnackBarClose}
        autoHideDuration={2000}
        message={t('copy_code_successfully')}
        data-testid="copyCodeMessage"
      />
    </>
  );
}
