const styles = (theme) => ({
  wrapper: {
    paddingTop: 16,
    marginBottom: 24,
    '@media(max-width: 600px)': {
      padding: '16px 20px',
    },
  },
  titleWrapper: {
    textAlign: 'center',
    marginBottom: 24,
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize28,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
    '@media(max-width: 600px)': {
      fontSize: theme.typography.fontSize20,
      textAlign: 'left',
    },
  },
  colorTitle: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize28,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  stepItemWrapper: {
    padding: '8px 0',
    margin: '0 -12px',
  },
  stepItem: {
    padding: '0 16px',
    '@media(max-width: 600px)': {
      padding: 0,
      marginBottom: 24,
    },
    '&:last-child': {
      marginBottom: 0,
      paddingBottom: '0 !important',
    },
  },
  btnWrapper: {
    width: '100%',
    textAlign: 'center',
    marginTop: 24,
  },
  findCarBtn: {
    width: '100%',
    maxWidth: '100%',
  },
});

export default styles;
