import React from 'react';
import Image from 'next/legacy/image';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import styles from '@/components/Cards/WhyLumi/WhyLumiCard.style';

const useStyles = makeStyles()(styles);

export default function WhyLumiCard({ iconSrc, title, text }) {
  const { classes } = useStyles();
  return (
    <Card className={classes.card}>
      <div className={classes.cardIcon}>
        <Image src={iconSrc} alt={title} width={64} height={64} quality={100} />
      </div>
      <CardContent className={classes.cardContent}>
        <Typography variant="h5" className={classes.cardTitle}>
          {title}
        </Typography>
        <Typography className={classes.cardText}>{text}</Typography>
      </CardContent>
    </Card>
  );
}
