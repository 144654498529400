import React from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import _get from 'lodash/get';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';

import { makeStyles } from 'tss-react/mui';

import ENDPOINTS from '@lumirental/lumi-web-sdk/dist/constants/app/ENDPOINTS';
import LIST_THRESHOLD from '@lumirental/lumi-web-shared/lib/constants/app/LIST_THRESHOLD';
import SEO_PAGE_ROUTES from '@lumirental/lumi-web-shared/lib/constants/app/SEO_PAGE_ROUTES';
import getLocaleRoute from '@lumirental/lumi-web-shared/lib/utils/getLocaleRoute';

import { onTapEvent } from '@/utils/gtm';

import BranchList from '@/components/Lists/Branch';

import styles from '@/blocks/City/CityBlock.style';

const useStyles = makeStyles()(styles);

export default function CityBlock({ appEnv, cityList, language }) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  // set initial state
  const listCountThreshold = LIST_THRESHOLD.CITY / 2;
  const slicedCityList = cityList?.slice(0, listCountThreshold);
  // derive
  const baseURL = ENDPOINTS.BASE_URL[appEnv];
  const viewAllUrl = `${getLocaleRoute(language, SEO_PAGE_ROUTES.CITY)}`;

  const showViewAllBtn = cityList?.length !== slicedCityList?.length;

  const handleCityClick = (city) => {
    const citySlug = _get(city, 'slug', '');
    // send GTM event
    onTapEvent('Home', `${citySlug}_tapped`);
  };
  const sendGTMEvent = () => {
    // send GTM event
    onTapEvent('Home', `view_all_cities_tapped`);
  };

  return (
    <div className={classes.wrapper}>
      <Container>
        <Grid container>
          <>
            <Grid container className={classes.cityRow}>
              <BranchList
                listData={slicedCityList}
                baseURL={baseURL}
                branchRoute={SEO_PAGE_ROUTES.CITY}
                language={language}
                handleBranchClick={handleCityClick}
              />
            </Grid>
            {showViewAllBtn && (
              <div className={classes.btnWrapper}>
                <Link
                  passHref
                  href={viewAllUrl}
                  className={classes.allBranchesBtn}
                  legacyBehavior
                >
                  <Button
                    className={classes.allBranchesBtn}
                    alt={t('view_all_web')}
                    title={t('view_all_web')}
                    onClick={sendGTMEvent}
                    target="_blank"
                  >
                    {t('view_all_web')}
                  </Button>
                </Link>
              </div>
            )}
          </>
        </Grid>
      </Container>
    </div>
  );
}
