const styles = (theme) => ({
  wrapper: {
    padding: '32px 16px',
    overflow: 'hidden',
    '@media(max-width: 600px)': {
      padding: '20px',
    },
  },
  carouselContainer: {
    '@media(max-width: 600px)': {
      padding: 0,
    },
  },
  wrapperGrid: {
    width: '100%',
  },
  titleWrapper: {
    marginBottom: 24,
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize28,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 4,
    '@media(max-width: 600px)': {
      fontSize: theme.typography.fontSize20,
    },
  },
  subTitle: {
    color: theme.palette.common.grey400,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    marginBottom: 8,
  },
  embla__prev: {
    padding: 0,
    border: 0,
    background: 'lightgrey',
    borderRadius: '50%',
    marginRight: 16,
  },
  embla__next: {
    padding: 0,
    border: 0,
    background: 'lightgrey',
    borderRadius: '50%',
  },
  sliderWrapper: {
    overflow: 'hidden',
  },
  embla__container: {
    display: 'flex',
    '@media(min-width: 768px)': {
      padding: '15px',
    },
  },
  embla__viewport: {
    marginBottom: 16,
  },
  slideItem: {
    minWidth: '33.33%',
    flex: '0 0 100%',
    width: 'fit-content',
    height: '100%',
    padding: 0,
    '@media(min-width: 768px)': {
      flex: '0 0 32%',
    },
    marginRight: 16,
  },
  card: {
    width: '100%',
    borderRadius: 8,
  },
  cardContent: {
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    minHeight: 220,
    '&:after': {
      content: '""',
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      bottom: 0,
      background: `linear-gradient(180deg, rgba(0, 0, 0, 0) 31.82%, ${theme.palette.common.black} 88.18%)`,
      opacity: 0.8,
    },
  },
  contentInner: {},
  cardText: {
    position: 'absolute',
    bottom: 24,
    left: 24,
    width: '100%',
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize15,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightSemiBold,
    zIndex: theme.zIndex.zIndex10,
  },
});

export default styles;
