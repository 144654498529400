import React from 'react';
import { useTranslation } from 'react-i18next';
import _get from 'lodash/get';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';
import getArabicDayWithLabel from '@lumirental/lumi-web-shared/lib/utils/date/getArabicDayWithLabel';
import getTimeWithArabicLabel from '@lumirental/lumi-web-shared/lib/utils/date/getTimeWithArabicLabel';
import goToMapURL from '@lumirental/lumi-web-shared/lib/utils/goToMapURL';

import styles from '@/components/Cards/Location/LocationCard.style';

const useStyles = makeStyles()(styles);

export default function LocationCard({ language, location }) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const isArabic = LANGUAGES.AR === language;
  const id = _get(location, 'id');
  const branchName = _get(location, 'name', '');
  const cityName = _get(location, 'cityName', '');
  const directions = _get(location, 'directions', '');
  const phoneNumber = _get(location, 'phoneNumber', '');
  const timingSummary = _get(location, 'timingSummary', []);

  const formattedBranchName = `${branchName}, ${cityName}`;
  const tel = `tel:+${phoneNumber}`;
  const hasDirections = _get(directions, 'length', 0) !== 0;

  // go to branch location
  const goToBranchLocation = (branch) => {
    const directionsURL = _get(branch, 'directions', '');
    if (directionsURL.length > 0) {
      // go to map Location.
      goToMapURL(directionsURL);
    }
  };

  return (
    <Grid item key={id} className={classes.card}>
      <div className={classes.header}>
        <Typography className={classes.subTitle}>
          {formattedBranchName}
        </Typography>
      </div>
      <div className={classes.infoWrapper}>
        {timingSummary.map((item) => {
          const timeLabel = _get(item, 'timeLabel', '');
          const dayLabel = _get(item, 'dayLabel', '');
          const formattedTime = isArabic
            ? getTimeWithArabicLabel(timeLabel)
            : timeLabel;
          const formattedDay = isArabic
            ? getArabicDayWithLabel(dayLabel)
            : dayLabel;

          return (
            <React.Fragment key={item.dayLabel}>
              <Typography className={classes.timingSummary}>
                <span>{formattedDay}</span>
                <span className={classes.timingSummaryTime}>
                  {formattedTime}
                </span>
              </Typography>
            </React.Fragment>
          );
        })}
        <Typography className={classes.directionSec}>
          <Button
            component="a"
            href={tel}
            variant="text"
            className={classes.phoneBtn}
          >
            {phoneNumber}
          </Button>
          {hasDirections && (
            <Button
              variant="text"
              onClick={() => goToBranchLocation(location)}
              className={classes.dirBtn}
            >
              {t('Get_direction')}
            </Button>
          )}
        </Typography>
      </div>
    </Grid>
  );
}

LocationCard.propTypes = {
  location: PropTypes.object.isRequired,
};
