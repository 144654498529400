import React from 'react';
import PropTypes from 'prop-types';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';

export default function NavigateBackIcon({ language }) {
  if (language === LANGUAGES.EN) {
    return <NavigateBeforeIcon />;
  }

  return <NavigateNextIcon />;
}

NavigateBackIcon.lengthpropTypes = {
  language: PropTypes.string,
};
