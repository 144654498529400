'use client';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles } from 'tss-react/mui';

import AirportBranchBlock from '@/blocks/AirportBranch';
import CityBlock from '@/blocks/City';
import CityBranchBlock from '@/blocks/CityBranch';
import BranchesTab from '@/components/Tabs/Branches';

import styles from '@/blocks/BranchTabs/BranchTabsBlock.style';

const useStyles = makeStyles()(styles);

export default function BranchTabsBlock({
  language,
  screenName,
  title,
  airportContent,
  cityBranchList,
  cityList,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(0);

  const tabs = [
    { id: 0, title: t('airport_branches_web') },
    { id: 1, title: t('city_SEO') },
    { id: 2, title: t('city_branches_web') },
  ];

  const checkActiveTab = (tabIndex) => {
    return tabIndex === selectedTab ? 'active' : '';
  };

  const handleChangeTab = (evt, currenTab) => {
    setSelectedTab(currenTab);
  };

  return (
    <div className={classes.wrapper}>
      <BranchesTab
        tabs={tabs}
        selectedTab={selectedTab}
        handleChangeTab={handleChangeTab}
      />

      <div className={`${classes.tab} ${checkActiveTab(0)}`}>
        <AirportBranchBlock
          title={title}
          screenName={screenName}
          airportList={airportContent}
        />
      </div>
      <div className={`${classes.tab} ${checkActiveTab(1)}`}>
        <CityBlock title={title} language={language} cityList={cityList} />
      </div>
      <div className={`${classes.tab} ${checkActiveTab(2)}`}>
        <CityBranchBlock
          title={title}
          screenName={screenName}
          cityBranchList={cityBranchList}
        />
      </div>
    </div>
  );
}
