const styles = (theme) => ({
  wrapper: {
    padding: '16px 0px',
  },
  cityRow: {
    padding: 0,
    margin: '0 -8px',
    width: 'calc(100% + 16px)',
  },
  btnWrapper: {
    width: '100%',
    textAlign: 'center',
    margin: 0,
  },
  allBranchesBtn: {
    textTransform: 'none',
    fontSize: theme.typography.fontSize14,
    fontWeight: theme.typography.fontWeightBold,
    '& .MuiButton-iconSizeMedium': {
      backgroundColor: theme.palette.common.purple400,
      padding: 2,
      borderRadius: '100%',
    },
  },
});

export default styles;
