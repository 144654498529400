const styles = () => ({
  locationTableWrapper: {
    padding: 0,
    marginBottom: 24,
    '@media(max-width: 600px)': {
      padding: '0px 20px',
    },
  },
});

export default styles;
