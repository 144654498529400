const styles = (theme) => ({
  locationTableBody: {
    padding: 0,
  },

  noLocationFoundWrapper: {
    padding: '24px 0',
    textAlign: 'center',
  },
  notLocationFoundContent: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize16,
    lineHeight: 1.6,
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  section: {
    marginBottom: 24,
  },
  title: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize16,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightBold,
    marginBottom: 16,
  },
  btnWrapper: {
    padding: 0,
    textAlign: 'center',
  },
  viewAllBtn: {
    textTransform: 'none',
  },
});

export default styles;
