import PropTypes from 'prop-types';

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import LANGUAGES from '@lumirental/lumi-web-shared/lib/constants/lang/LANGUAGES';

export default function NavigateForwardIcon(props) {
  const { language } = props;
  if (language === LANGUAGES.EN) {
    return <NavigateNextIcon {...props} />;
  }

  return <NavigateBeforeIcon {...props} />;
}

NavigateForwardIcon.lengthpropTypes = {
  language: PropTypes.string,
};
