import React from 'react';
import { useTranslation } from 'react-i18next';
import Image from 'next/legacy/image';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import styles from '@/components/Cards/WhatsHot/News/NewsCard.style';

const useStyles = makeStyles()(styles);

export default function NewsCard({ data }) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  const { backgroundImg, content, icon } = data;
  const label = t('news');

  return (
    <Card className={classes.card}>
      {backgroundImg && (
        <div className={classes.bgWrap}>
          <Image
            alt={content}
            src={backgroundImg}
            layout="fill"
            objectFit="cover"
            quality={100}
          />
        </div>
      )}
      <CardContent className={classes.cardContent}>
        <div className={classes.contentInner}>
          {label && (
            <Chip size="small" label={label} className={classes.chip} />
          )}

          <div className={classes.cardTextWrap}>
            {icon && (
              <div className={classes.icon}>
                <Image src={icon} alt={content} width={32} height={32} />
              </div>
            )}
            {content && (
              <Typography className={classes.cardText}>{content}</Typography>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
}
