import _get from 'lodash/get';

import CARD_TYPE from '@lumirental/lumi-web-shared/lib/constants/app/CARD_TYPE';

import ApplicationCard from '@/components/Cards/WhatsHot/Application';
import NewsCard from '@/components/Cards/WhatsHot/News';
import OfferCard from '@/components/Cards/WhatsHot/Offer';
import TestimonialCard from '@/components/Cards/WhatsHot/Testimonial';

/**
 * Returns formatted card data
 * @param {string} cardType
 * @param {string} cardData
 * @returns {Object}
 */
export function cardDataFactory(cardType, cardData) {
  let data = {
    backgroundImg: '',
    content: '',
    author: '',
    submittedAt: '',
  };

  switch (cardType) {
    case CARD_TYPE.NEWS: {
      const title = _get(cardData, 'title', '');
      const backgImg = _get(cardData, 'backgroundImg.url', '');
      const icon = _get(cardData, 'icon.url', '');
      data = {
        backgroundImg: backgImg,
        content: title,
        icon,
      };
      break;
    }
    case CARD_TYPE.TESTIMONIAL: {
      const content = _get(cardData, 'content', '');
      const author = _get(cardData, 'author', '');
      const submittedAt = _get(cardData, 'submittedAt', '');
      data = {
        content,
        author,
        submittedAt,
      };
      break;
    }
    case CARD_TYPE.OFFER: {
      const content = _get(cardData, 'offer.description', '');
      const code = _get(cardData, 'offer.code', '');
      const backgImg = _get(cardData, 'backgroundImg.url', '');
      data = {
        backgroundImg: backgImg,
        content,
        code,
      };
      break;
    }
    case CARD_TYPE.APPLICATION: {
      const desc = _get(cardData, 'description', '');
      const backgImg = _get(cardData, 'backgroundImage.url', '');
      data = {
        backgroundImg: backgImg,
        content: desc,
      };
      break;
    }
    default:
      break;
  }

  return data;
}

/**
 * Card Component Factory
 */
export const cardComponentFactory = {
  news: {
    comp: (data) => <NewsCard data={data} />,
  },
  offer: {
    comp: (data, locale, showSnackbar) => (
      <OfferCard data={data} showSnackbar={showSnackbar} />
    ),
  },
  testimonial: {
    comp: (data) => <TestimonialCard data={data} />,
  },
  application: {
    comp: (data, locale) => <ApplicationCard data={data} language={locale} />,
  },
};
