'use client';

import React from 'react';
import { useTranslation } from 'react-i18next';

import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';

import { makeStyles } from 'tss-react/mui';

import Locations from '@/components/Lists/Location/Locations';

import styles from '@/blocks/LocationTable/LocationTableBlock.style';

const useStyles = makeStyles()(styles);

export default function LocationTableBlock({
  branchRegionList,
  getRegionNameByRegionId,
  getBranchesByRegionId,
  isBranchSuccess,
  language,
  showViewMore = false,
}) {
  const { classes } = useStyles();
  const { t } = useTranslation();

  return (
    <div id="our_location">
      <div className={classes.tableHeaderWrapper}>
        <Container>
          <Grid container>
            <Grid item sm={12}>
              <List className={classes.tableHeader}>
                <ListItem className={classes.tableCell}>
                  <Typography variant="h4" className={classes.tableHeading}>
                    {t('Region')}
                  </Typography>
                </ListItem>
                <ListItem className={classes.tableCell}>
                  <Typography variant="h4" className={classes.tableHeading}>
                    {t('Branch_name')}
                  </Typography>
                </ListItem>
                <ListItem className={classes.tableCell}>
                  <Typography variant="h4" className={classes.tableHeading}>
                    {t('City')}
                  </Typography>
                </ListItem>
                <ListItem className={classes.tableCell}>
                  <Typography variant="h4" className={classes.tableHeading}>
                    {t('Timings')}
                  </Typography>
                </ListItem>
                <ListItem className={classes.tableCell}>
                  <Typography variant="h4" className={classes.tableHeading}>
                    {t('Current_number')}
                  </Typography>
                </ListItem>
                <ListItem className={classes.tableCell}>
                  <Typography variant="h4" className={classes.tableHeading}>
                    {t('Location')}
                  </Typography>
                </ListItem>
              </List>
            </Grid>
          </Grid>
        </Container>
      </div>
      <Locations
        branchRegionList={branchRegionList}
        getRegionNameByRegionId={getRegionNameByRegionId}
        getBranchesByRegionId={getBranchesByRegionId}
        isBranchSuccess={isBranchSuccess}
        language={language}
        showViewMore
      />
    </div>
  );
}
