const styles = (theme) => ({
  card: {
    display: 'flex',
    height: '100%',
    border: `1px solid ${theme.palette.common.grey50}`,
    borderRadius: 8,
    boxShadow: 'none',
  },
  cardIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 16,
    minWidth: 134,
    '@media(max-width: 600px)': {
      minWidth: 78,
    },
    height: 'inherit',
    '& > img': {
      width: '100%',
      maxWidth: 64,
    },
  },
  cardContent: {
    width: '100%',
    padding: 24,
    '@media(max-width: 600px)': {
      padding: 16,
    },
  },
  cardTitle: {
    color: theme.palette.common.blue400,
    fontSize: theme.typography.fontSize18,
    lineHeight: 1.3,
    fontWeight: theme.typography.fontWeightSemiBold,
    marginBottom: 16,
    '@media(max-width: 600px)': {
      marginBottom: 8,
      fontSize: theme.typography.fontSize16,
      lineHeight: 1.2,
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  cardText: {
    color: theme.palette.common.grey400,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
    fontWeight: theme.typography.fontWeightMedium,
    '@media(max-width: 600px)': {
      letterSpacing: theme.typography.subtitle2.letterSpacing,
    },
  },
});

export default styles;
