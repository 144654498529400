const styles = (theme) => ({
  card: {
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.common.blue400,
    borderRadius: 8,
    minHeight: 220,
    direction: 'ltr',
    boxShadow: 'none',
  },
  cardContent: {
    padding: 16,
    position: 'relative',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    minHeight: 220,
    '&:last-child': {
      paddingBottom: 16,
    },
  },

  contentInner: {
    padding: 8,
    position: 'relative',
    zIndex: theme.zIndex.zIndex100,
  },
  widget: {
    padding: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: 172,
  },
  content: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.5,
    marginBottom: 18,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'normal',
    display: '-webkit-box !important',
    lineClamp: 3,
    boxOrient: 'vertical',
  },
  chipWrapper: {
    textAlign: 'right',
    marginBottom: 8,
  },
  chip: {
    height: 19,
    padding: '0 10px',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.blue400,
    '& > .MuiChip-label': {
      padding: 0,
      color: theme.palette.common.blue400,
      fontSize: theme.typography.fontSize14,
      lineHeight: 1.5,
    },
  },
  dateWrapper: {
    padding: 0,
  },
  date: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.3,
  },
  commentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  commentItem: {
    maxWidth: '50%',
  },
  commentContent: {
    display: 'flex',
    alignItems: 'center',
  },
  author: {
    color: theme.palette.common.white,
    fontSize: theme.typography.fontSize14,
    lineHeight: 1.3,
  },
  commentImg: {
    width: '100%',
    marginRight: 10,
    minWidth: 32,
  },
  reviewImg: {
    maxWidth: 32,
    width: '100%',
    marginRight: 8,
  },
  reviewText: {
    color: theme.palette.text.primary,
    fontSize: theme.typography.fontSize13,
    lineHeight: 1.5,
  },
  saveTimeMain: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: 8,
  },
});

export default styles;
