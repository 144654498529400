import IconButton from '@mui/material/IconButton';

import NavigateForwardIcon from '@/components/Icons/NavigateForward';

export default function SlickArrowRight({
  currentSlide,
  slideCount,
  language,
  ...props
}) {
  return (
    <IconButton {...props} component="span">
      <NavigateForwardIcon language={language} />
    </IconButton>
  );
}
